 
.masthead {
	width: 100%;
	position: relative;
	background: #002855;
	text-align: left;

	--bracket: 60px;
	--padding: 20px;

	@media (max-width: 768px) {
		margin-bottom: calc(0px - var(--bracket) / 2);
	}

	@media (min-width: 769px) {
		--padding: 30px;
	}

	@media print {
		--padding: 0px;
		margin-bottom: 1em;
		background: none;
		border-bottom: 1px solid #000;
	}

	.masthead-narrative {
		--header-color: #fff;
		padding: calc(var(--padding) * 1.5) calc(var(--bracket) / 2) calc(var(--bracket) + 1.5 * var(--padding))
			calc(var(--bracket) / 2);

		@media (min-width: 769px) {
			min-height: 180px;
			display: flex;
			padding-top: var(--padding);
			padding-bottom: var(--padding);
			padding-left: var(--spacing);
			padding-right: var(--spacing);
			flex-direction: column;
			justify-content: center;
		}

		@media print {
			--header-color: #000;
			min-height: auto;
		}
	
		h1 {
			display: block;
			width: 100%;
			margin: 0;
			text-align: left;
		}
	}

	figure {
		margin: 0;
	}

	.masthead-image,
	.masthead-video {
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.has-featured-image & {
		display: flex;
		flex-direction: column;

		@media (min-width: 769px) {
			flex-direction: row-reverse;
		}

		.masthead-image,
		.masthead-video {
			min-height: 320px;
			position: relative;
			margin: 0;

			@media (min-width: 769px) {
				width: 50%;
				min-height: revert;
				.fullwidth & {
					height: 100%;
				}
			}

			img,
			video {
				position: absolute;
				@media (max-width: 768px) {
					-webkit-mask-image: none;
					mask-image: none;
				}
				@media (min-width: 769px) {
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}

		.masthead-narrative {
			@media (min-width: 769px) {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			&-text {
				width: 100%;
			}
		}
	}

	.bracket-upper {
		width: 100%;
		position: absolute;
		bottom: var(--bracket);
		left: 0;
		right: 0;
		height: var(--bracket);
	
		@media (min-width: 768px) {
			display: none;
		}
	
		&:before {
			content: "";
			display: block;
			width: var(--bracket);
			height: var(--bracket);
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath fill='white' d='M100,100H0V0H100ZM100,0H0A100,100,0,0,0,100,100h0Z'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		&:after {
			@media (min-width: 769px) {
				content: "";
				display: block;
				width: var(--bracket);
				height: var(--bracket);
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath fill='white' d='M100,0V100H0V0ZM0,0V100H0A100,100,0,0,0,100,0H0Z'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
	}
	
	.bracket-lower {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		overflow: hidden;
		height: var(--bracket);
	
		@media (min-width: 768px) {
			display: none;
		}
	
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: #fff;
			position: absolute;
			bottom: 0;
			left: 0;
			border-radius: 0 var(--bracket) 0 0;
			@media (min-width: 769px) {
				width: 50%;
			}
		}
		&:after {
			@media (min-width: 769px) {
				content: "";
				display: block;
				width: 50%;
				height: 100%;
				background: #fff;
				position: absolute;
				bottom: 0;
				right: 0;
				border-radius: var(--bracket) 0 0 0;
			}
		}
	}
}
