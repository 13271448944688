.globalnav {
	flex-grow: 1;
	width: 100%;
	margin-left: var(--spacing);

	font-family: var(--serif);
	font-size: 1.125em;

	display: flex;
	justify-content: flex-end;

	@media (max-width: 1023px) {
		display: none;
	}

	a,
	button {
		text-decoration: none;
		margin: 0;
		display: inline-flex;
		align-items: center;
		-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
		page-break-inside: avoid; /* Firefox */

		white-space: nowrap;
		background: none;
		border: none;

		color: var(--text-color);
		font-family: inherit;
		font-size: 1em;

		&.active,
		&:focus,
		&:hover {
			text-decoration: underline;
			cursor: pointer;
			svg {
				//transform: rotate(180deg);
			}			
		}

		span {
			margin-right: 9px;
		}

		svg {
			transition: transform 0.25s var(--easing);
			width: 11px;
			height: 6px;
			fill: currentColor;
		}

		&:not(.has-child) {
			&:after {
				width: 12px;
				height: 12px;
				margin-left: 0;
			}
		}
	}

	a{ // Show the rotation if the JS does not kick in a convert the links to buttons when there is a child menu.
		&.active,
		&:focus,
		&:hover {
			svg {
				transform: rotate(180deg);
			}			
		}
	}

	.parent-menu {
		margin: 0;
		padding: 0;
		list-style: none;

		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;

		max-width: 750px;
		width: 100%;

		// no js
		> li:has( > a){
			position: relative;
			&:hover,
			&:focus,
			&:focus-within{
				.sub-menu{
					display: block;
				}
			}
		}

		// js
		li.active {
			position: relative;
			.sub-menu{
				display: block;
			}
		}

	}

	.sub-menu {
		display: none;
		position: absolute;
		bottom: -1em;
		left: 50%;
		transform: translate(-50%, 100%);
		margin: 0;
		padding: calc(var(--spacing) / 2) var(--gutterWidth) var(--gutterWidth) var(--gutterWidth);


		border-top: 3px solid var(--text-color);
		font-size: 1rem;
		list-style: none;

		box-shadow: 0px 2px 4px #00000029;

		background: var(--background);
		column-count: 2;
		gap: 0;
		z-index: 100;

		&:before{
			position: absolute;
			top: -20px;
			left: 0;
			width: 100%;
			height: 20px;
			display: block;
			content: "";
		}

		a {
			width: 220px;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--text-highlight);
			padding: 17px;
		}

		li{
			margin: 0;
		}

		li.full {
			column-span: all;
			font-family: var(--sansserif);
			font-size: 0.8125em;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.65px;
			order: 1000;
			margin: calc(0px - var(--gutterWidth));
			margin-top: 0;			

			a {
				width: 100%;
				justify-content: center;
				padding: 1.5em;
				margin: calc(var(--spacing) / 2) 0 0 0;
				background: var(--text-highlight);
				color: var(--text-color-inverted);

				&:focus,
				&:hover {
					background: var(--text-highlight);
				}
			}
		}

		svg{
			display: none;
		}
	}
}
