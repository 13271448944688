/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * proxima-nova:
 *   - http://typekit.com/eulas/00000000000000007735e603
 *   - http://typekit.com/eulas/00000000000000007735e601
 *   - http://typekit.com/eulas/00000000000000007735e609
 * utopia-std:
 *   - http://typekit.com/eulas/0000000000000000000131a3
 *   - http://typekit.com/eulas/0000000000000000000131a2
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-04-01 15:26:37 UTC"}*/

// TODO: defer this with JS
// @import url("https://p.typekit.net/p.css?s=1&k=fib2nrs&ht=tk&f=139.171.175.13378.13379&a=706986&app=typekit&e=css");

@font-face {
	font-family: "proxima-nova";
	src: url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-700.woff2")
	// src: url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3")
			format("woff2"),
		url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-700.woff")
		// url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3")
			format("woff"),
		url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-700.otf")
		// url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3")
			format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: "proxima-nova";
	src: url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-100.woff2")
	// src: url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n1&v=3")
			format("woff2"),
		url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-100.woff")
		// url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n1&v=3")
			format("woff"),
			url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-100.otf")
		// url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n1&v=3")
			format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 100;
	font-stretch: normal;
}

@font-face {
	font-family: "proxima-nova";
	src: url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-400.woff2")
	// src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3")
			format("woff2"),
		url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-400.woff")
		// url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3")
			format("woff"),
		url("/wp-content/themes/mu-2022/dist/fonts/proxima-nova-400.otf")
		// url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3")
			format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "utopia-std";
	src: url("/wp-content/themes/mu-2022/dist/fonts/utopia-std.woff2")
	// src: url("https://use.typekit.net/af/9ff5df/0000000000000000000131a3/27/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3")
			format("woff2"),
		url("/wp-content/themes/mu-2022/dist/fonts/utopia-std.woff")
		// url("https://use.typekit.net/af/9ff5df/0000000000000000000131a3/27/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3")
			format("woff"),
		url("/wp-content/themes/mu-2022/dist/fonts/utopia-std.otf")
		// url("https://use.typekit.net/af/9ff5df/0000000000000000000131a3/27/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3")
			format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "utopia-std";
	src: url("/wp-content/themes/mu-2022/dist/fonts/utopia-std-italic.woff2")
	// src: url("https://use.typekit.net/af/aeaf25/0000000000000000000131a2/27/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i4&v=3")
			format("woff2"),
		url("/wp-content/themes/mu-2022/dist/fonts/utopia-std-italic.woff")
		// url("https://use.typekit.net/af/aeaf25/0000000000000000000131a2/27/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i4&v=3")
			format("woff"),
		url("/wp-content/themes/mu-2022/dist/fonts/utopia-std-italic.otf")
		// url("https://use.typekit.net/af/aeaf25/0000000000000000000131a2/27/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i4&v=3")
			format("opentype");
	font-display: swap;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}
