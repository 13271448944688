body {
	--serif: "utopia-std", Georgia, serif;
	--sansserif: "proxima-nova", Arial, sans-serif;
	--monospace: Monaco, Menlo, Consolas, "Courier New", monospace;

	--text-color: #1c1d1f;
	--header-color: #002855;
	--text-highlight: #0d64b2;
	--background: #fff;
	--hover: #345276;

	--text-grey: #767676;

	--background-grey: #f7f7f7;

	--text-color-inverted: #fff;
	--text-highlight-inverted: #fff;
	--background-inverted: #002855;

	--easing: cubic-bezier(0, 0.73, 0.27, 1);
	--spacing: 20px;
	@media (min-width: 769px) {
		--spacing: 36px;
	}
	--spacing-large: 150px;

	--columns: 4;

	@media (min-width: 1024px) {
		--columns: 12;
	}

	--gutterWidth: 16px;
	--guttersTotalWidth: calc((var(--columns) - 1) * var(--gutterWidth));
	--columnsAreaWidth: min(1400px, calc(100vw - var(--scrollbar-width, 0px) - (2 * var(--spacing))));

	--columnWidth: calc((var(--columnsAreaWidth) - var(--guttersTotalWidth)) / var(--columns));
	--left-menu-width: calc((var(--columnWidth) * 3) + (2 * var(--gutterWidth)));

	// svgs
	--checkmark: url('data:image/svg+xml,%3Csvg%20 viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M2 12.7018L10.3333 24L27 1" stroke="white" stroke-width="3"/%3E%3C/svg%3E');
	--home-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.5 10.5'%3E%3Cpath d='M13.5 5.3c0 .1 0 .2-.1.2l-.2.3c-.1.1-.2.2-.3.2-.1 0-.2 0-.2-.1l-.7-.5v4.4c0 .4-.3.7-.8.7h-3c-.4 0-.8-.3-.8-.8V7.3H6v2.4c0 .4-.3.8-.8.8h-3c-.4 0-.8-.3-.8-.8V5.4l-.5.5C.8 6 .7 6 .6 6c-.1 0-.2-.1-.3-.1l-.2-.3C0 5.5 0 5.4 0 5.3c0-.1.1-.2.1-.3L5.9.3c.2-.2.6-.3.8-.3s.6.1.8.3L10.9 3V1.7c0-.1.1-.2.2-.2h.8c.1 0 .2.1.2.2v2.2L13.5 5c-.1.1 0 .2 0 .3zm-2.6-.8L6.8 1.1 2.6 4.5v4.9h2.3V6.9c0-.4.3-.8.8-.8H8c.4 0 .8.3.8.8v2.4h2.3l-.2-4.8z' /%3E%3C/svg%3E");
	--external-link-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath style='fill:currentColor;' d='M10.5 7.1v3.8c0 .6-.5 1.1-1.1 1.1H1.1C.5 12 0 11.5 0 10.9V2.6c0-.6.5-1.1 1.1-1.1h3.8c.2 0 .4.2.4.4v.4c0 .2-.2.4-.4.4H1.3c-.1 0-.1.1-.1.1v8c0 .1.1.1.1.1h8c.1 0 .1-.1.1-.1V7.2c0-.2.2-.4.4-.4h.4c.1 0 .3.1.3.3zM12 .3v3.8c0 .2-.2.3-.3.3h-.8c-.2 0-.3-.2-.3-.3l.1-1.7L4 8.9c0 .1-.1.1-.2.1s-.2 0-.2-.1l-.5-.5C3 8.3 3 8.3 3 8.2s0-.2.1-.2l6.5-6.5H7.8c-.2 0-.3-.2-.3-.3V.3c0-.1.1-.3.3-.3h3.8c.2 0 .4.2.4.3z'/%3E%3C/svg%3E");
	--search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' %3E%3Cg%3E %3Cpath d='M11.9,11.4l-0.5,0.5c-0.1,0.1-0.3,0.1-0.4,0L8.2,9.1C8.1,9,8.1,9,8.1,8.9V8.6c-0.9,0.7-2,1.2-3.2,1.2C2.2,9.8,0,7.6,0,4.9 S2.2,0,4.9,0s4.9,2.2,4.9,4.9c0,1.2-0.4,2.3-1.2,3.2h0.3c0.1,0,0.2,0,0.2,0.1l2.8,2.8C12,11.1,12,11.3,11.9,11.4z M8.6,4.9 c0-2.1-1.7-3.8-3.8-3.8S1.1,2.8,1.1,4.9s1.7,3.8,3.8,3.8S8.6,7,8.6,4.9z'/%3E %3C/g%3E%3C/svg%3E");
	--giphy: url('data:image/svg+xml,%3Csvg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="4 2 16.32 20"%3E%3Cg%3E%3Cpath d="M4 3.714h2.331v16.572H4z" fill="%2304ff8e"/%3E%3Cpath d="M17.989 8.286h2.331v12h-2.331z" fill="%238e2eff"/%3E%3Cpath d="M4 19.714h16.32V22H4z" fill="%2300c5ff"/%3E%3Cpath d="M4 2h9.326v2.286H4z" fill="%23fff152"/%3E%3Cpath d="M17.989 6.571V4.286h-2.332V2h-2.331v6.857h6.994V6.571" fill="%23ff5b5b"/%3E%3Cpath d="M17.989 11.143V8.857h2.331" fill="%23551c99"/%3E%3Cpath d="M13.326 2v2.286h-2.332" fill="%23999131"/%3E%3C/g%3E%3C/svg%3E');
	--xclose: url('data:image/svg+xml,%3Csvg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 13.5 13.5" style="enable-background:new 0 0 13.5 13.5;" xml:space="preserve"%3E%3Cg stroke="%23fff152" %3E%3Cline  class="st0" x1="13.5" y1="0" x2="0" y2="13.5"/%3E%3Cline  class="st0" x1="13.5" y1="13.5" x2="0" y2="0"/%3E%3C/g%3E%3C/svg%3E');

	// image brackets
	--image-bracket-out-top: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 5000'%3E%3Cpath d='M.1,273.57c0-94.92,78.82-135.15,199.51-135.15h296.1c140,0,172.16-37,202.73-127.13h3.22C730.62,95,758,138.42,901.2,138.42h299.29C1317.93,138.42,1400,177,1400,273.57L1400,0H0ZM0,0H1400V5000H0Z'/%3E%3C/svg%3E");
	--image-bracket-out-right: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5000 1400'%3E%3Cpath d='M4726.43.1c94.92,0,135.15,78.82,135.15,199.51v296.1c0,140,37,172.16,127.13,202.73v3.22c-83.68,29-127.13,56.32-127.13,199.54v299.29c0,117.44-38.62,199.51-135.15,199.51l273.57,0V0ZM5000,0V1400H0V0Z'/%3E%3C/svg%3E");
	--image-bracket-out-bottom: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 5000'%3E%3Cpath d='M1399.9,4726.43c0,94.92-78.82,135.15-199.51,135.15H904.29c-140,0-172.16,37-202.73,127.13h-3.22c-29-83.68-56.32-127.13-199.54-127.13H199.51C82.07,4861.58,0,4823,0,4726.43L0,5000H1400ZM1400,5000H0V0H1400Z'/%3E%3C/svg%3E");
	--image-bracket-out-left: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5000 1400'%3E%3Cpath d='M273.57,1399.9c-94.92,0-135.15-78.82-135.15-199.51V904.29c0-140-37-172.16-127.13-202.73v-3.22C95,669.38,138.42,642,138.42,498.8V199.51C138.42,82.07,177,0,273.57,0L0,0V1400ZM0,1400V0H5000V1400Z'/%3E%3C/svg%3E");

	--image-bracket-in-top: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 5000'%3E%3Cpath d='M1399.9,0c0,94.92-78.82,135.15-199.51,135.15H904.29c-140,0-172.16,37-202.73,127.12h-3.22C669.38,178.6,642,135.15,498.8,135.15H199.51C82.07,135.15,0,96.53,0,0V4988.47H1400Z'/%3E%3C/svg%3E");
	--image-bracket-in-right: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5000 1400'%3E%3Cpath d='M4988.47,1399.9c-94.91,0-135.14-78.82-135.14-199.51V904.29c0-140-37-172.16-127.13-202.73v-3.22c83.68-29,127.13-56.32,127.13-199.54V199.51C4853.33,82.07,4892,0,4988.47,0H0V1400Z'/%3E%3C/svg%3E");
	--image-bracket-in-bottom: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 5000'%3E%3Cpath d='M.1,4988.47c0-94.91,78.82-135.14,199.51-135.14h296.1c140,0,172.16-37,202.73-127.13h3.22c29,83.68,56.32,127.13,199.54,127.13h299.29c117.44,0,199.51,38.62,199.51,135.14V0H0Z'/%3E%3C/svg%3E");
	--image-bracket-in-left: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5000 1400'%3E%3Cpath d='M0,.1C94.92.1,135.15,78.92,135.15,199.61v296.1c0,140,37,172.16,127.12,202.73v3.22C178.6,730.62,135.15,758,135.15,901.2v299.29C135.15,1317.93,96.53,1400,0,1400H4988.47V0Z'/%3E%3C/svg%3E");

	// video svgs
	// --video-play: url("data:image/svg+xml,%3Csvg width='752pt' height='752pt' version='1.1' viewBox='0 0 752 752' xmlns='http://www.w3.org/2000/svg'%3E %3Cg%3E %3Cpath d='m455.56 477.82h-38.359c-8.0508 0-14.207-6.1562-14.207-14.207v-175.22c0-7.5781 6.1562-14.207 14.207-14.207h38.359c8.0508 0 14.207 6.6289 14.207 14.207v175.7c0 7.5742-6.1562 13.73-14.207 13.73z'/%3E %3Cpath d='m567.33 477.82h-38.359c-8.0508 0-14.207-6.1562-14.207-14.207v-175.22c0-7.5781 6.1562-14.207 14.207-14.207h38.359c8.0508 0 14.207 6.6289 14.207 14.207v175.7c0 7.5742-6.6289 13.73-14.207 13.73z'/%3E %3Cpath d='m357.06 363.69-165.28-95.188c-9.4727-5.6836-21.312 1.4219-21.312 12.312v190.85c0 10.891 11.84 17.996 21.312 12.312l165.28-95.66c9.4727-5.6836 9.4727-18.945 0-24.629z'/%3E %3C/g%3E%3C/svg%3E");
}
