.site-header {
	border-bottom: 1px solid #E5E5E5;

	background: var(--background);
	position: sticky;
	top: 0;
	z-index: 10;

	
	@media (max-width: 1023px){
		.has-alert &{
			top: 32px;
		}
		height: 90px;
	}

	@media (max-width: 374px) {
		position: relative;
	}
	
	@media print {
		display: none;
	}
}

.header{
	display: flex;
	flex-direction: row;
	align-items: center;
	// padding: 0 var(--spacing);
	// max-width: calc( 1400px + (2 * var(--spacing) ) );
	// margin: 0 auto;
	height: 90px;
	
	// width: 100%;

	@media (max-width: 1023px){
		// height: 90px;
		// position: fixed;
	}
	
	//specificity is to override default WP styles
	.wp-block-site-logo.is-default-size {
		position: relative;
		z-index: 100;
		flex-grow: 1;
		
		.logo {
			display: block;
			width: 153px;
	
			@media(min-width: 1024px){
				width: 203px;
			}
		}

		img {
			width: 100%;
			height: auto;
		}
	}

}



.nav-global {
	display: none;
	@media(min-width: 1024px){
		display: block;
	}
}


.mobile-showhide {
	padding: 2px;
	margin-left: 1rem;
	background: none;
	border: none;
	font-size: 1em;
	cursor: pointer;
	@media(min-width: 1024px){
		display: none;
	}
	svg {
		stroke: currentColor;
		width: 20px;
		height: auto;
	}
}

@import "header-search";
