@import "reset.scss";
@import "variables.scss";

html,
body {
	padding: 0;
	margin: 0;
	font-family: var(--sansserif);
	color: var(--text-color);
	position: relative;
	// height: 100%;
}

.wp-site-blocks {
	min-height: 100%;
}

.lockscrolling {
	height: 100%;
	overflow: hidden;
}

.visuallyhide {
	position: absolute;
	left: -100000px;
}

.nowrap {
	white-space: nowrap;
}

a {
	color: var(--text-highlight);

	&:hover,
	&:focus {
		color: var(--header-color);
		.has-white-color &{
		  color: inherit;
		}		
	}
}


body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
	max-width: initial !important;
}  

// Define our focus styles
:is(a, button, input, textarea, summary) {
	--outline-size: max(2px, 0.08em);
	--outline-style: dotted;
	--outline-color: currentColor;

	&:focus {
		outline: var(--outline-size) var(--outline-style) var(--outline-color);
		outline-offset: var(--outline-offset, var(--outline-size));

		&:not(:focus-visible) {
			outline: none;
		}
	}

	&:focus-visible {
		outline: var(--outline-size) var(--outline-style) var(--outline-color);
		outline-offset: var(--outline-offset, var(--outline-size));
	}
}

// define how we contain the content areas
.wrapper{
	max-width: 1400px;
	margin: 0 auto;

	&.wide{
		max-width: calc(1400px + ( var(--spacing) * 2) ); 
	}

	.wrapper-inner{
		// outline: 2px solid lightgreen;
		margin: 0 var(--spacing); 
		// width: 100%;
		flex-grow: 1;
	}

	.alignwide{
		max-width: initial;
		margin: 0 !important;
		@media(min-width: 1024px){
			margin: 0 calc(0px - var(--columnWidth) - var(--gutterWidth) ) !important;
		}
	}

	.alignfull{
		max-width: initial;
		margin: 0 calc(0px - var(--spacing) ) !important;
		@media(min-width: 1024px){
			margin: 0 calc(0px - var(--spacing) - var(--columnWidth) - var(--gutterWidth) ) !important;
		}
	}

	.content-and-navigator{	
		.alignfull,
		.alignwide{
			@media(min-width: 1024px){
				margin-left: 0 !important;
			}
		}
	}
}

@import "fonts";

@import "eyebrow-critical";
@import "header";
@import "globalnav";
@import "resources-critical";
@import "supernav-critical";
@import "leftnav";
@import "mobilenav";

@import "masthead";
@import "breadcrumbs";

@import "content";
@import 'blocks/block-image';

