.eyebrow-and-mobilenav {
	flex-shrink: 0;

	@media (max-width: 1023px) {
		visibility: hidden;
		position: fixed;
		z-index: 50;
		top: 90px;
		right: -100%;
		bottom: 0;
		width: 100%;
	}

	.mobilenav-body{

		@media (max-width: 1023px) {
			box-shadow: 0px 2px 4px #00000029;
			max-height: 100%;
		
			opacity: 0;
			transform: translate(0, 0);
			will-change: auto;
			transition: opacity 0.25s var(--easing), transform 0.25s var(--easing);
	
			width: 100%;
			min-height: 100%;
			max-width: 425px;
			background: var(--background);
			display: flex;
			flex-direction: column;
			overflow: auto;
			overscroll-behavior: contain;
	
			.has-alert & {
				top: 122px;
			}
		}
	
		@media (prefers-reduced-motion) {
			transition: none;
		}
	}

	.navigator-close {
		position: absolute;
		top: -62px;
		left: calc(0px - var(--spacing) - 25px);
		margin: 0;
		padding: 5px;
		border: none;
		font-size: 1em;
		background: var(--background);
		display: none;
		cursor: pointer;

		span {
			display: none;
		}

		svg {
			stroke: currentColor;
			width: 20px;
			height: 20px;
		}
		@media (min-width: 1024px) {
			display: none;
		}
	}

	.supernav {
		flex-grow: 1;
	}

	.navigator-close,
	.supernav {
		@media (min-width: 1024px) {
			display: none;
		}
	}
}

// show the nav menu on mobile
.eyebrow-and-mobilenav.active,
#mobilenav:target {
	visibility: visible;
	.navigator-close {
		display: block;
	}
	.mobilenav-body{
		@media (max-width: 1023px) {
			opacity: 1;
			pointer-events: auto;
			transform: translate(-100%, 0);
		}
	}
}
