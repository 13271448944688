.navigator {
	flex-shrink: 0;

	position: relative;
	margin-right: var(--gutterWidth);
	width: var(--left-menu-width);

	@media ( max-width: 1023px ){
		display: none;
	}

	@media print {
		display: none;
	}

}
