.skipnav {
	transform: translateX(-100%);
	opacity: 0;
	transition: top 0.25s var(--easing);
	z-index: 100;
	@media (min-width: 1024px) {
		transform: translateY(-100%);
	}

	&:active,
	&:focus {
		transform: translateX(0);
		opacity: 1;
		@media (min-width: 1024px) {
			transform: translateY(0);
		}
	}
}

.eyebrow {
	color: var(--text-color-inverted);
	background: var(--background-inverted);
	// width: 100%;
	display: block;
	font-size: 0.875rem;
	font-weight: 600;
	text-transform: uppercase;

	@media (min-width: 1024px) {
		font-size: 0.75rem;
		display: block;
		top: 0;
	}

	&.mobile{
		@media(min-width: 1024px){
			display: none;
		}
	}

	.eyebrow-inner {
		@media (min-width: 1024px) {
			align-items: center;
			height: 45px;
			// max-width: calc(1400px + (2 * var(--spacing)));
			// margin: 0 auto;
			padding: 0 var(--spacing);
			position: relative;
		}
	}
	@media (min-width: 1024px) {
		// hide the links until the non critical css shows up
		a {
			position: absolute;
			top: -10000px;
		}
	}

	svg {
		width: 12px;
		height: 12px;
		fill: var(--text-color-inverted);
		transform: rotate(-90deg);

		@media (min-width: 1024px) {
			height: 12px;
			transform: none;
			margin-left: 1em;
		}
	}
}
