/*
* This file should only contain css that relates directly to the Supernav's look that is critical on load. 
* See the following for positioning and layout
* Mobile Fly out: supernav-mobile-(noncritical|critical).scss
* Desktop Left side : supernav-desktop-(noncritical|critical).scss
*/
.supernav {
	position: relative;
	padding-top: var(--spacing);

	@media(min-width: 1024px){
		padding-top: 0;
		overflow: hidden;
	}
	

	&:before {
		@media (min-width: 1024px) {
			--shadow-color: #0000000a;
			content: "";
			height: calc(100% - 24px);
			top: 0;
			right: 0;
			width: 16px;
			margin: 8px 0;
			position: absolute;
			box-shadow: -0.5px 0px 1.2px rgba(0, 0, 0, 0.018), -1.4px 0px 3.1px rgba(0, 0, 0, 0.026),
				-2.9px 0px 6.2px rgba(0, 0, 0, 0.032), -5.2px 0px 11.2px rgba(0, 0, 0, 0.038),
				-8.7px 0px 19.7px rgba(0, 0, 0, 0.044), -14.3px 0px 36.1px rgba(0, 0, 0, 0.052);
		}
	}

	&:after {
		@media (min-width: 1024px) {
			content: "";
			width: 21px;
			right: 0;
			height: calc(100% + 16px);
			position: absolute;
			top: -1em;
			background: #fff;
		}
	}


	.supernav-previous {
		transform: translateX(-100%);
		position: absolute;
		width: 100%;
	}

	.supernav-next {
		transform: translateX(100%);
		position: absolute;
		width: 100%;
	}

	.supernav-inactive {
		transition: transform ease-in 0.25s;
		width: 100%;
		// outline: 5px dotted magenta;
		}
	.supernav-current {
		transition: transform ease-in 0.25s;
		transform: translateX(0);
		width: 100%;
		// outline: 5px solid orange;
	}

	h2 {
		
		margin: 0;
		.fullwidth .content .wp-block-group & {
			margin: 0;
		}
	}


	ul, li{
		list-style: none;
		margin: 0;
		padding: 0;
		
	}
	li:last-child{
		a.child::before, button.child::before, p.child::before{
			height: 100%;
		}
	}

	a,
	button,
	p {
		width: 100%;
		font-size: 1.125rem;
		font-family: var(--serif);
		line-height: 1.5;
		text-decoration: none;
		margin: calc(var(--spacing) - 10px) 0;
		padding: 0 var(--spacing);
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border: none;
		background: none;
		color: var(--text-highlight);
		text-align: left;
		cursor: pointer;

		@media (min-width: 1024px) {
			padding-right: var(--spacing);
			padding-left: 0; 	
			font-size: 1rem;
		}

		span {
			position: relative;
			display: inline-block;
			border-bottom: 2px solid transparent;
			transition: border 0.25s var(--easing);
			margin-right: 10px;
			@media (prefers-reduced-motion) {
				transition: none;
			}
		}

		svg {
			width: 12px;
			fill: currentColor;
			transform: rotate(-90deg);
			flex-shrink: 0;
		}

		&.current-page,
		&:focus:not(p),
		&:hover:not(p) {
			color: var(--text-color);
			span {
				border-bottom-color: var(--text-color);
			}
		}

		&:is(p) {
			cursor: default;
		}

		&.back {
			justify-content: initial;
			font-size: 0.75em;
			text-transform: uppercase;
			font-family: var(--sansserif);
			font-weight: 600;
			margin-bottom: calc(0px - (var(--spacing) / 3));
			margin-top: 0;
			svg {
				order: -1;
				transform: rotate(90deg);
				width: 14px;
				padding-right: 3px;
				margin-right: 8px;
			}
		}

		&.parent {
		}

		&.child {
			padding-left: calc(20px + var(--spacing));
			@media(min-width: 768px){
				padding-left: 20px;

			}

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: calc(0px - var(--spacing));
				display: block;
				border-left: 2px solid var(--text-color);
				opacity: 0.2;
				height: calc(100% + var(--spacing) - 10px);
				width: 1px;
				top: 0;
				opacity: 0.05;
			}
			&:last-child::before {
				// height: 100%;
			}
		}
	}
}
