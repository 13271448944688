.content-holder {
	max-width: calc(1400px + (2 * var(--spacing)));
	margin: 0 auto;
	padding: 0;

	@media (max-width: 1023px) {
	}

	@media (min-width: 1024px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}


// add some spacing if we have a few specific blocks as the last thing
.entry-content .wp-block-mu-contact:last-child,
.entry-content .wp-block-mu-people:last-child{
	margin-bottom: 100px;
  }

// for full width blank pages
.content-holder.fullwidth {
	// display: flex;
	position: relative;

	.masthead {
		margin: 0 calc(0px - var(--spacing)) !important;
		width: auto;
	}

	@media (min-width: 1024px) {
		.content {
			max-width: calc((9 * var(--gutterWidth)) + (10 * var(--columnWidth)));
			margin: 0 auto;
		}
		.masthead {
			margin: 0 calc(0px - var(--spacing) - var(--columnWidth) - var(--gutterWidth)) !important;
			width: auto;
		}

		.content-and-navigator,
		.breadcrumbs {
			margin: 0 calc(0px - var(--columnWidth) - var(--gutterWidth)) !important;
			width: auto;
		}

		.breadcrumbs {
			padding-left: 0;
		}

		.breadcrumbs + .content-and-navigator{
			// margin-top: -10px;
		}		
	}
}

// To accommodate the variable width page size when using a more break.
.content-and-navigator {
	position: relative;
	display: flex;

	.navigator {
		flex-shrink: 0;

		.has-expanded-content & {
			@media (min-width: 1024px) {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	.masthead .wp-block-post-title,
	.content-top {
		width: 100%;
		@media (min-width: 1024px) {
			padding-right: calc( var(--columnWidth) + var(--gutterWidth) );  
		}
		.has-expanded-content & {
			@media (min-width: 1024px) {
				margin-left: calc(var(--gutterWidth) + var(--left-menu-width)) !important;
				max-width: calc(var(--gutterWidth) * 7 + var(--columnWidth) * 8) !important;
			}
		}
	}
	.content-fullwidth {
		max-width: calc((9 * var(--gutterWidth)) + (10 * var(--columnWidth))) !important;
		margin: 0 auto !important;
	}
}

.content {
	line-height: 1.5;
	width: 100%;

	*{ // offset any anchor links
		scroll-margin-top: 115px;
	}

	.fullwidth & {
		max-width: 100%;
		width: 100%;
	}

	@media (min-width: 1024px) {
		max-width: calc(var(--gutterWidth) * 7 + var(--columnWidth) * 8);

		.has-expanded-content & {
			width: 100%;
			max-width: revert;
		}
	}

	> div {
		max-width: initial;
	}

	p:empty {
		display: none;
	}
	table {
		width: 100%;
		overflow: auto;

		thead{
			th, td{
				padding: 1.5625rem 1.125rem;
				border-bottom: 1px solid #00285555;
			}
		}

		th{
			border: 0 none;
		}

		td {
			border: none;
			padding: 1.5625rem 1.125rem;
		}
		tr:nth-of-type(2n) {
			background: #f0f0f0;
		}

	}

	.is-style-bare table {
		th {
			padding: 0 1.375rem 1.4375rem 0;
			border: 0 none;
		}
		td {
			padding: 0 1.375rem 1.4375rem 0;
			border: 0 none;
		}
		tr:nth-of-type(2n) {
			background: none;
		}
	}
}


body :not(.has-expanded-content) .content-holder:not(.fullwidth) .content{
	// background: pink;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--serif);
	text-wrap: balance;
	
	strong {
		font-weight: inherit;
	}

	&.has-no-balance{
		text-wrap: auto;
	}
}

h1 {
	position: relative;
	line-height: 1;
	font-size: 5rem;
	line-height: 0.95;
	font-family: var(--serif);
	font-weight: 400;
	color: var(--header-color);

	@media (min-width: 769px) {
		font-size: clamp(3rem, 6vw, 5rem);
	}
}

h2 {
	font-family: var(--serif);
	font-size: 2.625rem;
	line-height: 1;
	word-break: break-word;
	hyphens: auto;
	position: relative;
	z-index: 5;
	font-weight: 400;
	color: var(--header-color);

	@media (min-width: 769px) {
		font-size: 3.75rem;
		:where( .page-template-default:not(.has-expanded-content) ) &  {
			font-size: 2.625rem;
		}		
	}
	.wp-block-post-content > &,
	.content-top > &,
	.fullwidth .content-top > &,
	.content-and-navigator > .wp-block-group > & {
		&:first-child {
			margin-top: 0;
		}
	}
}


h3 {
	font-size: 2rem;
	font-weight: 400;
	font-family: var(--serif);
	color: var(--header-color);
	margin: 1em 0 0 0;
	line-height: 1.1;

	@media (min-width: 769px) {

	}	
}

h4 {
	font-size: 1.625rem;
	font-family: var(--serif);
	font-weight: 400;
	letter-spacing: 0.78px;
	color: var(--header-color);
	margin: 1em 0 0 0;
}

h5 {
	letter-spacing: 0.57px;
	font-size: 1.1875rem;
	font-weight: 400;
	font-family: var(--serif);
	color: var(--header-color);
	margin: 1em 0 0 0;
}

.page-title {
	color: var(--mu-dark-blue);
	text-align: center;
	font-family: var(--sansserif);
	font-weight: 100;
	font-size: 1.25rem;
	letter-spacing: 2.88px;
	color: #022855;
	text-transform: uppercase;
	opacity: 1;

	@media (min-width: 769px) {
		font-size: 1.5rem;
	}
}

.hero {
	@extend h1;
	text-shadow: 0px 3px 6px #00000029;
	font-size: 3rem;
	font-size: calc(clamp(3rem, 0.86rem + 10.71vw, 6rem));		

	@media (min-width: 769px) {
		font-size: 11.25rem;
		font-size: calc(clamp(7rem, 2.36rem + 9.66vw, 11.25rem));	
	}

	.is-style-smaller-top-text & {
		font-size: 2.5rem;
		font-size: calc(clamp(2.5rem, 1.43rem + 5.35vw, 4rem));

		@media (min-width: 769px) {
			font-size: 5rem;
			font-size: calc(clamp(5rem, 1.35rem + 7.59vw, 8rem));
		}
	}
}

.headline {
	font-family: var(--serif);
	font-size: 1.875rem;
	line-height: 1;
	word-break: break-word;
	hyphens: auto;
	position: relative;
	z-index: 5;
	font-weight: 400;
	color: var(--header-color);

	@media (min-width: 769px) {
		font-size: 3.75rem;
	}

}

.section-title {
	font-size: 2.625rem;
	font-weight: 400;
	font-family: var(--serif);
	color: var(--header-color);
	margin: 1em 0 0 0;

	@media (min-width: 769px) {
	}	

}

.block-title {
	@extend h4;
}

.component-title {
	font-weight: 300;
	font-family: var(--sansserif);
	font-size: 1.5rem;
	text-align: center;
	letter-spacing: 2.88px;
	text-transform: uppercase;
	color: var(--header-color);
	&.text-is-aligned-left {
		text-align: left;
	}
}

.tile-title {
	@extend h5;
}

.grey,
.gray {
	color: var(--text-grey);
}

.gray-background,
.grey-background {
	background: var(--background-grey);
}

.padding {
	padding: var(--spacing);
}

hr {
	color: #0000001a;
}

p {
	&.is-style-big-body {
		font-size: 1.25rem;
		letter-spacing: 0.1px;
	}
	a {
		overflow-wrap: break-word;
	}
}

// list items
ol,
ul {
	li {
		margin: 1em 0;
	}

	&.is-style-two-column,
	&.is-style-three-column,
	&.is-style-four-column {
		@media (min-width: 600px) {
			padding-left: 0;
			column-count: 2;
			column-gap: calc( 2 * var(--gutterWidth) );
			> li{
				margin-left: 1.5em;
				break-inside: avoid;
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
	&.is-style-three-column {
		@media (min-width: 600px) {
			column-count: 2;
		}

		@media (min-width: 768px) {
			column-count: 3;
		}
	}
	&.is-style-four-column {
		@media (min-width: 600px) {
			column-count: 2;
		}

		@media (min-width: 768px) {
			column-count: 3;
		}

		@media (min-width: 1000px) {
			column-count: 4;
		}
	}

	&.list-direction-row{
		@media (min-width: 600px) {
			padding: 0;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: calc( 2 * var(--gutterWidth) );
			li{
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	
		&.is-style-three-column {
			@media (min-width: 600px) {
				grid-template-columns: 1fr 1fr;
			}
			@media (min-width: 768px) {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}
		&.is-style-four-column {
			@media (min-width: 600px) {
				grid-template-columns: 1fr 1fr;
			}
			@media (min-width: 768px) {
				grid-template-columns: 1fr 1fr 1fr;
			}
			@media (min-width: 1000px) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}		
	}
	
	&.no-marker {
		list-style: none;
		padding-left: 0;
		li{
			margin-left: 0;
		}
	}
	
	&.check {
		list-style-type: "✓  ";
		::marker {
			color: var(--text-highlight);
			font-size: 1.2em;
		}
	}

	&.dash {
		list-style-type: "-  ";
	}

	&.no-list-style {
		list-style-type: none;
		padding-left: 0;
		li {
			margin: 0.75em 0;
		}
	}	
	&.lower-alpha {
		list-style-type: "lower-alpha";
	}
	&.lower-roman {
		list-style-type: "lower-roman";
	}
	&.upper-alpha {
		list-style-type: "upper-alpha";
	}
	&.upper-roman {
		list-style-type: "upper-roman";
	}
}

// border around blocks
.border {
	border: 1px solid var(--text-grey);
	padding: var(--spacing);
}

.wp-block-columns.spacer {
	.wp-block-column:first-of-type {
		border-right: 2px solid #ebebeb;
	}
}

// groups
.wp-block-group {
	.has-background {
		padding: var(--spacing);
	}

	&.alignfull {
		position: relative;
		padding: var(--spacing);
		@media (min-width: 1024px) {
			padding-left: calc(var(--spacing) + var(--columnWidth) + var(--gutterWidth));
			padding-right: calc(var(--spacing) + var(--columnWidth) + var(--gutterWidth));
			.content-top & {
				padding: var(--spacing);
			}

		}

		&.has-background:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			z-index: -1;
			left: 50%;
			// right: 50%;
			width: calc( 100vw - var(--scrollbar-width, 0px) ); 
			margin-left: calc( ( 50vw - ( var(--scrollbar-width, 0px) / 2 ) ) * -1);
			margin-right: calc( ( 50vw - ( var(--scrollbar-width, 0px) / 2 ) ) * -1);
			height: 100%;

			.content-top & {
				display: none;
			}

			.page-template-page-blank &,
			div.content-fullwidth & {
				// left: 50%;
				@media (min-width: 1400px) {
					// margin-left: 0;
					// margin-right: 0;
					// left: calc(((100 * var(--vw, 1vw)) - (9 * var(--gutterWidth) + 10 * var(--columnWidth)) - ((var(--spacing) + var(--columnWidth) + var(--gutterWidth)) * 2)) / -2);
				}
			}
			
		}
		&.has-shadow-blue-background-color:before {
			background: var(--wp--preset--color--shadow-blue);
		}
		&.has-light-blue-background-color:before {
			background: var(--wp--preset--color--light-blue);
		}
		&.has-grey-text-background-color:before {
			background: var(--wp--preset--color--grey-text);
		}
		&.has-grey-background-background-color:before {
			background: var(--wp--preset--color--grey-background);
		}
	}
}
