.site-search {
	@media(min-width: 1024px){
		padding-left: var(--spacing);
	}

	.search-form {
		position: absolute;
		top: -2px;
		right: 62px;
		left: var(--spacing);
		height: 90px;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: -1;
		overflow: hidden;
		
		@media(min-width: 768px){
			right: 65px;
			top: 0px;
		}

		@media(min-width: 1024px){
			right: var(--spacing);
			left: 270px;		
		}

		form {
			display: none;
			background: #fff;
			transform: translateX(100%);
			transition: 
				.25s transform var(--easing), 
				.25s visibility cubic-bezier(1.000, 0.000, 1.000, 0.010),
				.25s z-index cubic-bezier(1.000, 0.000, 1.000, 0.010);
			gap: 20px;
			align-content: space-between;
			width: 100%;
			height: 100%;
			align-items: center;	
			// visibility: hidden;
			@media (prefers-reduced-motion) {
				transition: none;
			}			
		}
	}    

	.search-close {
		stroke: var(--text-color);
		display: flex;
		align-items: center;		
	}

	label {
		position: absolute;
		left: -10000px;
	}

	input[type="search"] {
		border: none;
		border-bottom: 1px solid var(--text-color);
		padding-bottom: 5px;
		background: none;
		border-radius: 0;
		flex-grow: 1;
		height: 30px;
		font-size: 1rem;
		width: 100%;
		-webkit-appearance: none; 

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
		  -webkit-appearance:none;
		}	
	
		&:focus:not(.focus-visible) {
			outline: none;
		}		
	}

	button {
		position: relative;
		z-index: 100;
		background: none;
		border: none;
		cursor: pointer;
		order: -1;
		padding: 0;		
	}

	svg {
		width: 20px;
		height: 20px;
		fill: var(--text-color);
		@media (min-width: 768px){
			width: 16px;
			height: 16px;
		}
	}
}

.search-form.open,
#searchForm:target {
	z-index: 100;
	form{
		transform: translateX(0);
		visibility: visible;
		display: inline-flex;
	}
}

.search-form.transition {
	form {
		display: inline-flex;
	}
}
